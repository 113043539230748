/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { TextField } from "@mui/material";
// import Icon from "@mui/material/Icon";
// Authentication layout components
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import * as moment from 'moment';
import { Oval } from 'react-loader-spinner';
import { jsPDF } from 'jspdf';
import html2canvas from "html2canvas";
import * as service from '../../services/service';

function ViewAdventureTourRegistration() {
    const [state, setState] = useState({ certificatefilename: '', certificatefilepath: '' });
    const [data, setData] = useState([{}]);
    const [documentData, setDocuemntData] = useState([{}]);
    const [equipmentData, setEquipmentData] = useState([{}]);
    // const [siteData, setSiteData] = useState([{}]);
    // const [siteStatus, setSiteStatus] = useState('');
    // const [siteVerificationDate, setSiteVerificationDate] = useState('');
    // const [expectedCompletionDate, setExpectedCompletionDate] = useState('');
    // const [remainingDays, setRemainingDays] = useState('');
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState('');
    const [errors, setErrors] = useState({});

    const navigate = useNavigate();
    const params = useParams();

    const getRegistrationById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_ADVENTURE_TOUR_REGISTRATION_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AdventureRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result.message.messageType === 'Success') {
                        setData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getDocumentsById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_ADVENTURE_TOUR_DOCUMENT_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AdventureRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === 'Success') {
                        setDocuemntData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getEquipmentsById = () => {
        setLoading(true);
        // const errs = {};        
        if (params.id > 0) {
            fetch(
                service.GET_ADVENTURE_TOUR_EQUIPMENT_BY_ID,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        AdventureRegistrationId: params.id,
                    })
                })
                .then(res => res.json())
                .then(result => {
                    if (result[0].message.messageType === 'Success') {
                        setEquipmentData(result);
                    }
                    else {
                        // eslint-disable-next-line
                        alert(result.message.message);
                    }
                });
        }
        setLoading(false);
    };

    const getSiteVisitDetails = () => {
        setLoading(true);
        fetch(
            service.GET_ADVENTURE_TOUR_SITE_VISIT_DETAILS,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    AdventureRegistrationId: params.id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result[0].message.messageType === "Failed") {
                    // setSiteStatus('Pending');
                }
                else {
                    // setSiteData(result);
                    // setSiteStatus(result[0].siteStatus);
                    // if (result[0].siteStatus === 'In Process') {
                    //     setSiteVerificationDate(new Date(result[0].siteVerificationDate));
                    //     setExpectedCompletionDate(new Date(result[0].expectedCompletionDate));
                    //     setRemainingDays(result[0].remainingDays);
                    // }
                }
            });
        setLoading(false);
    };

    const getCertificateById = () => {
        fetch(
            service.GET_ADVENTURE_CERTIFICATE_BY_ID,
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    AdventureRegistrationId: params.id,
                })
            })
            .then(res => res.json())
            .then(result => {
                if (result.message.messageType === 'Success') {
                    setState({
                        ...state, certificatefilename: result.fileName, certificatefilepath: result.filePath,
                    });
                }
            });
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        getRegistrationById();
        getDocumentsById();
        getEquipmentsById();
        getSiteVisitDetails();
        getCertificateById();
        setLoading(false);
    }, []);

    const backHandler = () => {
        navigate('/authentication/sign-in/basic');
    };

    const handleDownload = (fileName, filePath) => {
        setLoading(true);
        const formData = new FormData();
        formData.append("FileName", fileName);
        formData.append("FilePath", filePath);
        fetch(
            service.DOWNLOAD_FILE,
            {
                method: 'POST',
                body: formData
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });

        setLoading(false);
    };

    function getExtension(filename) {
        return filename.split('.').pop()
    }
    const validate = () => {
        let isValid = true;
        const errs = {};
        if (!file) {
            isValid = false;
            errs[".file"] = "Please upload file";
        }
        if (file && getExtension(file.name).toLowerCase() !== 'pdf'
            && getExtension(file.name).toLowerCase() !== 'jpg'
            && getExtension(file.name).toLowerCase() !== 'jpeg'
            && getExtension(file.name).toLowerCase() !== 'png'
            && getExtension(file.name).toLowerCase() !== 'docx') {
            isValid = false;
            errs[".file"] = "Please upload pdf/jpg/jpeg/png/docx file";
        }
        setErrors(errs);
        return isValid;
    }

    const submitHandler = (e, docName, docId) => {
        e.preventDefault();
        const errs = {};
        setLoading(true);
        if (validate()) {
            const formData = new FormData();
            formData.append("AdventureRegistrationId", params.id);
            formData.append("AdventureDocumentId", docId);
            formData.append(docName, file);

            fetch(
                service.UPDATE_ADVENTURE_TOUR_DOCUMENT_BY_ID,
                {
                    method: 'POST',
                    body: formData
                })
                .then(res => res.json())
                .then(result => {
                    if (result.messageType === 'Success') {
                        // eslint-disable-next-line
                        alert(result.message);
                        window.location.reload();
                        // navigate('/agrotourregistrationsuccess');
                    }
                    else if (result.messageType === 'Failed') {
                        // eslint-disable-next-line
                        alert(result.message);
                    }
                    else {
                        errs[".invalid"] = result.message.message;
                        setErrors(errs);
                    }
                });
        }
        setLoading(false);
    }

    const printDocument = () => {
        setTimeout(() => {
            setTimeout(() => {
                setLoading(true);
            }, 100);
            const divToPrint = document.querySelector('#divForm');
            html2canvas(divToPrint)
                .then((canvas) => {
                    const imgData = canvas.toDataURL('image/png');
                    const imgWidth = 190;
                    const pageHeight = 290;
                    const imgHeight = (canvas.height * imgWidth) / canvas.width;
                    let heightLeft = imgHeight;
                    // eslint-disable-next-line new-cap
                    const doc = new jsPDF('pt', 'mm');
                    let position = 0;
                    doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
                    heightLeft -= pageHeight;
                    while (heightLeft >= 0) {
                        position = heightLeft - imgHeight;
                        doc.addPage();
                        doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
                        heightLeft -= pageHeight;
                    }
                    doc.save('ApplicationForm.pdf');
                    setLoading(false);
                });
        }, 1000);
    }

    return (
        <div style={{ width: '100%' }}>
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={-3}
                                p={2}
                                mb={1}
                                textAlign="center"
                            >
                                <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                    Adventure Tour Registration Details
                                </MDTypography>
                            </MDBox>
                            {loading ? <Oval
                                heigth="100"
                                width="100"
                                color='grey'
                                ariaLabel='loading'
                            /> :
                                <MDBox pt={4} pb={3} px={3}>
                                    <MDBox component="form" role="form">
                                        {/* <div style={{ fontSize: '12px', color: 'red' }}>{errors}</div> */}
                                        <Card style={{ alignItems: 'center' }}>
                                            <MDBox pt={4} pb={3} px={3} style={{ width: '100%' }}>
                                                <div id="divForm" width="100%">
                                                    <TableContainer component={Paper} style={{ width: '100%', alignSelf: 'center' }}>
                                                        <Table id="tblReceipt" style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell colSpan={2}>
                                                                        <Table style={{ "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Email Id :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.emailId}
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Mobile No. :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.mobileNo}
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Registration Date :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {moment(data.addedDate).format("DD/MM/YYYY")}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Applicant Name :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.applicantName}
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Name of the Individual/Company/Partnership Firm/Trust etc. to be registered :
                                                                                </TableCell>
                                                                                <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.name}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Applicant Type :
                                                                                </TableCell>
                                                                                <TableCell colSpan="5" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.applicantType}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Category For Applicant Registration :
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell width="20%" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Adventure tour operator :
                                                                                </TableCell>
                                                                                <TableCell width="20%" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.operator ? "Yes" : "No"}
                                                                                </TableCell>
                                                                                <TableCell width="15%" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Aggregator / Resellers :
                                                                                </TableCell>
                                                                                <TableCell width="20%" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.aggregator ? "Yes" : "No"}
                                                                                </TableCell>
                                                                                <TableCell width="15%" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Camp / Resorts :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.camp ? "Yes" : "No"}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Training Institutes and Institutes e.g. Mountaineering Institutes, Paragliding Schools etc. :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.trainingInstitute ? "Yes" : "No"}
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Association / Federation :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.association ? "Yes" : "No"}
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Individual Oraganizer :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.individualOrganizer ? "Yes" : "No"}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Govenrment Oraganizer :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.governmentOrganizer ? "Yes" : "No"}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Name and Designation of the person authorized by the organization/company :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.authorizedPerson}
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Business Start Date :
                                                                                </TableCell>
                                                                                <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {moment(data.businessStartDate).format("DD/MM/YYYY")}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Applicant Address :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.applicantAddress}
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Correspondence Address if Different from Applicant Address :
                                                                                </TableCell>
                                                                                <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.coAddress}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Region :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.region}
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    District :
                                                                                </TableCell>
                                                                                <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.district}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Whats App Number :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.whatsAppNo}
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Applicant Website :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.applicantWebSite}
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Applicant Social Media Handles :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.socialMediaHandles}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Adventure Activity Category :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.activityCategory}
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Adventure Activities :
                                                                                </TableCell>
                                                                                <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.activities}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Nauka Vihar / Jal Krida
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.naukaViharJalkrida}
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Beach Name :
                                                                                </TableCell>
                                                                                <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.beachName}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Specify your activity if not listed above
                                                                                </TableCell>
                                                                                <TableCell colSpan="3" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.otherActivity}
                                                                                </TableCell>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Status :
                                                                                </TableCell>
                                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.status}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                            {data.activityCategory === 'Water Activities' ?
                                                                                <TableRow>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        Assistant Director Status
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        {data.assistantDirectorStatus}
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        Joint Director Status
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        {data.jointDirectorStatus}
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        Director Status
                                                                                    </TableCell>
                                                                                    <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                        {data.directorStatus}
                                                                                    </TableCell>
                                                                                </TableRow> : null}
                                                                            <TableRow>
                                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    Remarks
                                                                                </TableCell>
                                                                                <TableCell colSpan="6" style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                                    {data.remarks}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </Table>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                        <Table>
                                                            <TableRow>
                                                                <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    Equipments
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    Equipment Name
                                                                </TableCell>
                                                                {/* <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Units
                                                            </TableCell> */}
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    Manufacturer
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    Purchase Date
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableBody>
                                                                {equipmentData.map((row) => (
                                                                    <TableRow
                                                                        key={row.equipmentId}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {row.equipement}
                                                                        </TableCell>
                                                                        {/* <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                        {row.noOfUnits}
                                                                    </TableCell> */}
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {row.manufacturerName}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {row.purchaseDate ? moment(row.purchaseDate).format("DD/MM/YYYY") : ''}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                        <Table>
                                                            <TableRow>
                                                                <TableCell colSpan="6" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    Documents
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    Document Type / Adventure Activity
                                                                </TableCell>
                                                                <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    Document Name
                                                                </TableCell>
                                                                <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    File Name
                                                                </TableCell>
                                                                <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    Status
                                                                </TableCell>
                                                                <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    Verification Date
                                                                </TableCell>
                                                                <TableCell style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    Upload
                                                                </TableCell>
                                                            </TableRow>
                                                            <TableBody>
                                                                {documentData.map((row) => (
                                                                    <TableRow
                                                                        key={row.adventureDocumentId}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.documentType}</TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            <MDButton onClick={() => handleDownload(row.fileName, row.filePath)}>{row.documentName}</MDButton>
                                                                        </TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.fileName}</TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.status}</TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.verificationDate ? moment(row.verificationDate).format("DD/MM/YYYY") : ''}</TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {row.status === "Rejected" ?
                                                                                <div>
                                                                                    <TextField
                                                                                        id="file"
                                                                                        type="file"
                                                                                        onChange={ev => setFile(ev.target.files[0])}
                                                                                        style={{ width: 300 }}
                                                                                        required
                                                                                    />
                                                                                    <div style={{ fontSize: '12px', color: 'red' }}>{errors[".file"]}</div>
                                                                                    <MDButton color="info" onClick={e => submitHandler(e, row.documentName, row.adventureDocumentId)}>
                                                                                        Save
                                                                                    </MDButton>
                                                                                </div>
                                                                                : null}

                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                        {/* <Table>
                                                            <TableCell colSpan="3" style={{ fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                Site Visit Status
                                                            </TableCell>
                                                            <TableRow>
                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Criteria</TableCell>
                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Remarks</TableCell>
                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Status</TableCell>
                                                            </TableRow>
                                                            <TableBody>
                                                                {siteData.map((row) => (
                                                                    <TableRow
                                                                        key={row.criteriaId}
                                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                    >
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>{row.criteria}</TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {row.remarks}
                                                                        </TableCell>
                                                                        <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                            {row.status}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                                <TableRow>
                                                                    <TableCell colSpan="3" style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Site Visit Status : {siteStatus} </TableCell>
                                                                </TableRow>
                                                                {siteStatus === 'In Process' ?
                                                                    <TableRow>
                                                                        <TableCell style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Site Verification Date : {moment(siteVerificationDate).format('DD/MM/YYYY')} </TableCell>
                                                                        <TableCell style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Expected Completion Date : {moment(expectedCompletionDate).format('DD/MM/YYYY')} </TableCell>
                                                                        <TableCell style={{ fontSize: '14px', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Remaining Days : {remainingDays} </TableCell>
                                                                    </TableRow>
                                                                    : null}
                                                                {siteStatus === 'In Process' ?
                                                                    <TableRow>
                                                                        <TableCell colSpan="3" style={{ fontSize: '14px', color: 'red', fontWeight: 'bold', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>Kindly complete the pending Site Verification criteria whose Status has been marked `NO` on or before expected completion date. <br /> If you do not fulfil them on or before expected completion date, your application will get rejected.</TableCell>
                                                                    </TableRow>
                                                                    : null}
                                                            </TableBody>
                                                        </Table> */}
                                                        {/* <Table>
                                                            <TableRow>
                                                                <TableCell style={{ width: '20%', fontWeight: 'bold', fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    Certificate File
                                                                </TableCell>
                                                                <TableCell style={{ fontSize: '14px', "borderWidth": "1px", 'borderColor': "#aaaaaa", 'borderStyle': 'solid' }}>
                                                                    {state.certificatefilename ?
                                                                        <MDButton onClick={() => handleDownload(state.certificatefilename, state.certificatefilepath)}>{state.certificatefilename}</MDButton>
                                                                        : null}
                                                                </TableCell>
                                                            </TableRow>
                                                        </Table> */}
                                                    </TableContainer>
                                                </div>
                                            </MDBox>
                                            <MDBox mt={4} mb={1} textAlign="center">
                                                <Table>
                                                    <TableRow>
                                                        <TableCell>
                                                            <MDButton color="info" onClick={() => printDocument()}>
                                                                PRINT
                                                            </MDButton>
                                                        </TableCell>
                                                        <TableCell>
                                                            <MDButton color="secondary" onClick={backHandler}>
                                                                Log Out
                                                            </MDButton>
                                                        </TableCell>
                                                    </TableRow>
                                                </Table>
                                            </MDBox>
                                        </Card>
                                    </MDBox>
                                </MDBox>

                            }
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
        </div>
    );
}

export default ViewAdventureTourRegistration;
